var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search-user"},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("__manageuserplan_title")))]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.planOptions,"item-text":"name","item-value":"id","outlined":"","dense":"","label":_vm.$t('__user_plan')},on:{"change":function($event){return _vm.loadUsersByPlan()}},model:{value:(_vm.planSelect),callback:function ($$v) {_vm.planSelect=$$v},expression:"planSelect"}})],1)],1)],1),_c('v-card-text',[_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.userList,"items-per-page":10,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.start_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.start_time ? item.start_time : "-")+" ")]}},{key:"item.end_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.end_time ? item.end_time : "-")+" "),(item.expired)?_c('v-chip',{attrs:{"color":item.expired,"small":""}},[_vm._v(" "+_vm._s(item.expired === "grey" ? _vm.$t("__charge_plan_expired") : _vm.$t("__charge_plan_near_expired"))+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"color":"primary","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.toSetUserPlan(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("__set_plan")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.toUserInfo(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-card-account-details-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("__more_info")))])])],1)]}},{key:"item.actived",fn:function(ref){
var item = ref.item;
return [(item.actived)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check-bold ")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close-thick")]),(item['plan_id'])?[(item.actived)?_c('v-btn',{staticClass:"ml-2",attrs:{"small":""},on:{"click":function($event){return _vm.toSuspendPlan(item)}}},[_vm._v(" "+_vm._s(_vm.$t("__suspend"))+" ")]):_c('v-btn',{staticClass:"ml-2",attrs:{"small":""},on:{"click":function($event){return _vm.toCancelSuspend(item)}}},[_vm._v(" "+_vm._s(_vm.$t("__cancel_suspend"))+" ")])]:_vm._e()]}}],null,true)})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.userPlanDialog),callback:function ($$v) {_vm.userPlanDialog=$$v},expression:"userPlanDialog"}},[_c('UserPlanForm',{attrs:{"plan":_vm.userPlanDialogData},on:{"cancel":_vm.planFormCancel,"ok":_vm.planFormOk}})],1),_c('AlertDialog',{attrs:{"show":_vm.alertDialogShow,"title":_vm.alertDialogTitle,"text":_vm.alertDialogText,"oktext":_vm.$t('__confirm'),"canceltext":_vm.$t('__cancel')},on:{"ok":_vm.alertDialogOk,"cancel":_vm.alertDialogCancel}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }