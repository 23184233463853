<template>
  <div class="search-user">
    <v-card elevation="0">
      <v-card-title>{{ $t("__manageuserplan_title") }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-select
              :items="planOptions"
              item-text="name"
              item-value="id"
              outlined
              dense
              :label="$t('__user_plan')"
              v-model="planSelect"
              @change="loadUsersByPlan()"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-card outlined>
          <v-data-table
            :headers="headers"
            :items="userList"
            :items-per-page="10"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
          >
            <template v-slot:[`item.start_time`]="{ item }">
              {{ item.start_time ? item.start_time : "-" }}
            </template>
            <template v-slot:[`item.end_time`]="{ item }">
              {{ item.end_time ? item.end_time : "-" }}
              <v-chip :color="item.expired" small v-if="item.expired">
                {{
                  item.expired === "grey"
                    ? $t("__charge_plan_expired")
                    : $t("__charge_plan_near_expired")
                }}
              </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      class="mr-5"
                      dark
                      @click.stop="toSetUserPlan(item)"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>{{ $t("__set_plan") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      dark
                      @click.stop="toUserInfo(item.id)"
                    >
                      mdi-card-account-details-outline
                    </v-icon>
                  </template>
                  <span>{{ $t("__more_info") }}</span>
                </v-tooltip>
              </div>
            </template>
            <template v-slot:[`item.actived`]="{ item }">
              <v-icon color="success" v-if="item.actived">
                mdi-check-bold
              </v-icon>
              <v-icon color="error" v-else> mdi-close-thick</v-icon>
              <template v-if="item['plan_id']">
                <v-btn
                  small
                  class="ml-2"
                  v-if="item.actived"
                  @click="toSuspendPlan(item)"
                >
                  {{ $t("__suspend") }}
                </v-btn>
                <v-btn small class="ml-2" v-else @click="toCancelSuspend(item)">
                  {{ $t("__cancel_suspend") }}
                </v-btn>
              </template>
            </template>
          </v-data-table>
        </v-card>
        <v-dialog v-model="userPlanDialog" persistent max-width="600px">
          <UserPlanForm
            :plan="userPlanDialogData"
            @cancel="planFormCancel"
            @ok="planFormOk"
          />
        </v-dialog>
        <AlertDialog
          :show="alertDialogShow"
          :title="alertDialogTitle"
          :text="alertDialogText"
          :oktext="$t('__confirm')"
          :canceltext="$t('__cancel')"
          @ok="alertDialogOk"
          @cancel="alertDialogCancel"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { format, subMonths } from "date-fns";
import UserPlanForm from "@/components/ManageUser/UserPlanForm";

export default {
  name: "UserPlan",
  components: {
    UserPlanForm
  },
  computed: {
    ...mapGetters({
      isMobile: "isMobile",
      planList: "user/planList",
      planUsers: "user/planUsers",
      plansData: "user/planUsersPlanData"
    }),
    userList() {
      const userList = [];
      for (const user of this.planUsers) {
        userList.push({
          email: user.data.email,
          userName: `${user.data.last_name} ${user.data.first_name}`,
          join_time: this.formatTime(user.data.join_time),
          id: user.id
        });
      }
      for (const plan of this.plansData) {
        for (const list of userList) {
          if (list.id === plan["user_id"]) {
            list["plan_id"] = plan["plan_id"];
            list["start_time"] = this.formatTime(plan["start_time"]);
            list["end_time"] = this.formatTime(plan["end_time"]);
            list["is_suspended"] = plan["is_suspended"];
            list.expired = this.checkExpired(plan["end_time"]);
            list.actived = !plan["is_suspended"];
          }
        }
      }
      return userList;
    },
    planOptions() {
      let options = [];
      options = [...this.planList];
      options.unshift({
        name: this.$t("__charge_plan_unset"),
        id: "unset"
      });
      return options;
    }
  },
  data() {
    return {
      userPlanDialog: false,
      userPlanDialogData: null,
      userPlanUserID: "",
      headers: [
        {
          text: this.$t("__email"),
          align: "start",
          sortable: false,
          value: "email"
        },
        {
          text: this.$t("__first_name"),
          sortable: false,
          value: "userName"
        },
        { text: this.$t("__join_time"), value: "join_time" },
        { text: this.$t("__start_time"), value: "start_time" },
        { text: this.$t("__end_time"), value: "end_time" },
        { text: this.$t("__actived"), sortable: false, value: "actived" },
        {
          text: this.$t("__setting"),
          sortable: false,
          align: "center",
          value: "actions"
        }
      ],
      planSelect: "",
      sortBy: "join_time",
      sortDesc: true,
      alertDialogShow: false,
      alertDialogTitle: "",
      alertDialogText: "",
      alertDialogFunc: null,
      alertDialogData: null
    };
  },
  methods: {
    loadUsersByPlan() {
      let planID;
      if (this.planSelect === "unset") planID = "";
      else planID = this.planSelect;
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("user/getUsersByPlan", planID)
        .then(() => {
          this.loadUserPlans();
        })
        .catch(err => {
          this.$store.dispatch("setIsLoading", false);
          console.log(err);
        });
    },
    loadPlans() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("user/getPlans")
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          this.$store.dispatch("setIsLoading", false);
          console.log(err);
        });
    },
    loadUserPlans() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("user/getUserPlansByPlan", this.planSelect)
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          this.$store.dispatch("setIsLoading", false);

          console.log(err);
        });
    },
    toSetUserPlan(item) {
      this.userPlanDialogData = item;
      this.userPlanUserID = item.id;
      this.userPlanDialog = true;
    },
    toSuspendPlan(item) {
      this.alertDialogTitle = this.$t("__set_suspend_title");
      this.alertDialogText = this.$t("__set_suspend_text").replace(
        "%1",
        item.userName
      );
      this.alertDialogFunc = this.suspendHandler;
      this.alertDialogData = { userID: item.id, suspend: true };
      this.alertDialogShow = true;
    },
    toCancelSuspend(item) {
      this.alertDialogShow = true;
      this.alertDialogTitle = this.$t("__cancel_suspend_title");
      this.alertDialogText = this.$t("__cancel_suspend_text").replace(
        "%1",
        item.userName
      );
      this.alertDialogFunc = this.suspendHandler;
      this.alertDialogData = { userID: item.id, suspend: false };
      this.alertDialogShow = true;
    },
    suspendHandler({ userID, suspend }) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("user/suspendUserPlan", { userID, suspend })
        .then(() => {
          this.loadUsersByPlan();
        })
        .catch(err => {
          this.$store.dispatch("setIsLoading", false);
          console.log(err);
        });
    },
    alertDialogOk() {
      this.alertDialogShow = false;
      if (this.alertDialogFunc) this.alertDialogFunc(this.alertDialogData);
      this.alertDialogTitle = "";
      this.alertDialogText = "";
      this.alertDialogFunc = null;
      this.alertDialogData = null;
    },
    alertDialogCancel() {
      this.alertDialogShow = false;
      this.alertDialogTitle = "";
      this.alertDialogText = "";
      this.alertDialogFunc = null;
      this.alertDialogData = null;
    },
    planFormOk({ planID, startTime, endTime, suspend }) {
      this.$store.dispatch("setIsLoading", true);
      this.$store
        .dispatch("user/setUserPlan", {
          userID: this.userPlanUserID,
          planID,
          startTime,
          endTime,
          suspend
        })
        .then(() => {
          this.loadUsersByPlan();
          this.loadUserPlans();
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.log(err);
          this.$store.dispatch("setIsLoading", false);
        });
      this.userPlanDialog = false;
    },
    planFormCancel() {
      this.userPlanDialogData = null;
      this.userPlanUserID = "";
      this.userPlanDialog = false;
    },
    toUserInfo(userID) {
      this.$router.push(`/user-info/${userID}`);
    },
    checkExpired(endTime) {
      let today = format(new Date(), "T");
      let twoMonthAgo = subMonths(new Date(endTime), 2);
      let twoMonthAgoTimeStamp = format(twoMonthAgo, "T");
      let oneMonthAgo = subMonths(new Date(endTime), 1);
      let oneMonthAgoTimeStamp = format(oneMonthAgo, "T");
      let endTimeTimeStamp = format(new Date(endTime), "T");
      if (today >= oneMonthAgoTimeStamp) return "error";
      else if (today >= endTimeTimeStamp) return "grey";
      else if (today >= twoMonthAgoTimeStamp) return "yellow";
      else return null;
    },
    formatTime(time) {
      if (time === "0001-01-01T00:00:00Z" || time === "-") {
        return "-";
      }
      return format(new Date(time), "yyyy/MM/dd");
    }
  },
  watch: {
    planList: {
      immediate: true,
      handler(val) {
        if (val.length) {
          this.planSelect = this.planOptions[0].id;
        }
      }
    }
  },
  created() {
    this.loadPlans().then(() => {
      return this.loadUsersByPlan();
    });
  }
};
</script>
