<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ $t("__set_plan") }}</span>
    </v-card-title>
    <v-card-text class="pb-0">
      <v-form v-model="isValid">
        <v-container>
          <v-select
            :items="planList"
            item-text="name"
            item-value="id"
            :label="$t('__user_plan')"
            outlined
            dense
            required
            :rules="[requiredRule]"
            v-model="form.planID"
          >
          </v-select>
          <date-picker
            :date.sync="startTime"
            :date-label="$t('__start_time')"
            :locale="$i18n.locale"
            outlined
            dense
          />
          <date-picker
            :date.sync="endTime"
            :date-label="$t('__end_time')"
            :locale="$i18n.locale"
            :min-date="startTime"
            outlined
            dense
          />
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions class="pt-0">
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="cancel">
        {{ $t("__cancel") }}
      </v-btn>
      <v-btn color="blue darken-1" text @click="ok" :disabled="!isValid">
        {{ $t("__confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { format, formatISO, startOfDay, addYears } from "date-fns";
import { mapGetters } from "vuex";

export default {
  name: "UserPlanForm",
  computed: {
    ...mapGetters({
      planList: "user/planList"
    })
  },
  props: {
    plan: {
      type: Object
    }
  },
  data() {
    return {
      form: {
        planID: "",
        startTime: "",
        endTime: ""
      },
      startTime: format(new Date(), "yyyy-MM-dd"),
      endTime: "",
      isValid: false,
      requiredRule: v => !!v || this.$t("__required")
    };
  },
  methods: {
    loadPlans() {
      return this.$store
        .dispatch("user/getPlans")
        .then(() => {})
        .catch(err => {
          console.log(err);
        });
    },
    ok() {
      this.form.startTime = formatISO(startOfDay(new Date(this.startTime)));
      this.form.endTime = formatISO(startOfDay(new Date(this.endTime)));
      this.$emit("ok", this.form);
    },
    cancel() {
      this.$emit("cancel");
    }
  },
  watch: {
    plan: {
      immediate: true,
      handler(val) {
        if (val) {
          if (val["plan_id"]) {
            this.form.planID = val["plan_id"];
            this.startTime = format(new Date(val["start_time"]), "yyyy-MM-dd");
            this.endTime = format(new Date(val["end_time"]), "yyyy-MM-dd");
          }
        } else {
          this.startTime = format(new Date(), "yyyy-MM-dd");
          this.endTime = "";
          this.form = {
            planID: "",
            startTime: "",
            endTime: ""
          };
        }
      }
    },
    startTime: {
      immediate: true,
      handler(val) {
        let nextYear = addYears(new Date(val), 1);
        this.endTime = format(nextYear, "yyyy-MM-dd");
      }
    }
  },
  created() {
    this.loadPlans();
  }
};
</script>
